import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import useLocales from '#/hooks/useLocales';

import { lifeSituationQuestions } from '#/components/pages/CohabitationAgreement/stepsData';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import { useGetChildren } from '#/api/childrenQueries';
import { useGetServiceForm } from '#/lib/api/servicesQueries';

export default function LifeSituation() {
  const { translate } = useLocales();

  const { watch } = useFormContext();

  const { data: poaChildrenCheck } = useGetServiceForm('power-of-attorney');

  const { data: children } = useGetChildren();

  const disableOption = (value: string, questionKey: string) => {
    if (questionKey !== 'situation') return false;
    const haveChildren =
      poaChildrenCheck?.children_power_of_attorney?.has_children === 'yes' ||
      (children && children?.length > 0);

    return !!(
      haveChildren && value !== 'one_full_time_one_household_with_children'
    );
  };

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const hideLastQuestion = (currentRentalAgreement: string) =>
    currentRentalAgreement === 'both' || currentRentalAgreement === '';

  const translatedQuestions = lifeSituationQuestions.map((question, i) => ({
    ...question,
    question: String(
      translate(`cohabitationAgreement.stepTwo.questions.${i}.question`)
    ),
    answers: question.answers?.map((answer, j) => ({
      ...answer,
      label: String(
        translate(
          `cohabitationAgreement.stepTwo.questions.${i}.options.${j}.label`
        )
      ),
    })),
  }));

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing={3}>
        {translatedQuestions.map((question) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
            style={{
              ...(question.questionKey === 'change_contract_plans' &&
                hideLastQuestion(watch('current_rental_agreement_name')) && {
                  display: 'none',
                }),
            }}
          >
            <Stack direction="column" spacing={2}>
              {question.answers && (
                <RHFStyledRadioGroup
                  name={question.questionKey}
                  question={{
                    questionKey: question.questionKey,
                    question: question.question,
                    answers: question?.answers,
                  }}
                  disableOption={disableOption}
                />
              )}
              {question.children && (
                <>{question.children.content(watch('situation'))}</>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}
